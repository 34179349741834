<template>
    <div
        v-click-outside="{
            handler: closeFilter,
            include: include,
        }"
    >
        <v-card
            class="px-7 py-7 px-md-5 main_filter d-flex align-center"
            elevation="2"
        >
            <v-row
                class="d-flex align-center md-4 px-md-2"
                style="max-width: 100%; flex-wrap: nowrap"
            >
                <div class="filter_left d-flex align-center">
                    <slot
                        name="left_panel"
                        :events="{ updateShortcats }"
                    />
                </div>
                <div class="d-flex align-center">
                    <div
                        v-if="!!presets.length"
                        class="filter-preset-wrapper d-flex align-center"
                    >
                        <slot name="button" />
                        <div class="ml-10 mr-10">
                            <span
                                style="cursor: pointer"
                                @click="togglePresetsWindow"
                            >
                                <v-icon v-if="isPresetWindowOpen">mdi-close</v-icon>
                                <v-icon v-else>mdi-dots-horizontal</v-icon>
                            </span>
                            <the-preset-window
                                v-if="isPresetWindowOpen"
                                :presets="presets"
                                :active-preset="activePreset"
                                @setPreset="updateShortcats"
                                @closePresetsList="isPresetWindowOpen=false"
                            />
                        </div>
                    </div>
                    <div
                        class="filter_btn d-flex justify-end align-center flex-column"
                        style="cursor: pointer;"
                        @click="isShowFilter()"
                    >
                        <div class="d-flex justify-end align-center">
                            <div class="counter_wrapper">
                                <v-icon
                                    :style="!search.preset ? 'color: #000' : 'color: #9e9e9e'"
                                    size="35"
                                >
                                    mdi-filter-outline
                                </v-icon>
                                <span
                                    v-if="searchCounter > 0"
                                    class="ml-3 order_counter_filter"
                                >
                                    {{ searchCounter }}
                                </span>
                            </div>
                        </div>
                        <div
                            v-if="searchCounter > 0"
                            class="mx-2"
                        >
                            <span
                                class="font-weight-bold primary--text "
                                @click.stop="reset"
                            >
                                CLEAR
                            </span>
                        </div>
                    </div>
                </div>
            </v-row>
        </v-card>
        <v-slide-x-reverse-transition>
            <v-card
                v-show="showFilters"
                :class="showFilters ? 'px-4 pt-4' : 'px-4'"
                class="sidebar-filter"
                dense
            >
                <!-- HEADER -->
                <header class="w-full mb-3">
                    <v-row class="align-center">
                        <v-col cols="6">
                            <div class="text-subtitle-1">
                                Filters
                            </div>
                        </v-col>
                        <v-col
                            cols="6"
                            class="d-flex justify-end"
                        >
                            <v-btn
                                class="mr-4"
                                @click="reset"
                            >
                                <v-icon>
                                    mdi-delete-outline
                                </v-icon>
                                CLEAR
                            </v-btn>
                            <v-btn
                                icon
                                @click="closeFilter"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </header>

                <v-divider />

                <!-- BODY -->
                <section class="py-5">
                    <v-row>
                        <v-col
                            v-for="field in searchFields"
                            :key="field.value"
                            class="py-0"
                            cols="12"
                        >
                            <template v-if="field.value === 'search_by'">
                                <div class="d-flex">
                                    <v-select
                                        v-model="search.search_by"
                                        :items="field.list"
                                        item-value="value"
                                        item-text="text"
                                        label="Search field"
                                        outlined
                                        class="search_field"
                                        hide-details
                                    />
                                    <v-text-field
                                        v-model="search.search_for"
                                        label="Search"
                                        prepend-inner-icon="mdi-magnify"
                                        outlined
                                        class="search_input"
                                        hide-details
                                    />
                                </div>
                            </template>
                            <template v-else-if="field.list">
                                <v-select
                                    v-model="search[field.value]"
                                    class="included"
                                    :items="field.list"
                                    :label="field.label"
                                    hide-details
                                    outlined
                                />
                            </template>
                            <template v-else-if="field.value === 'datepicker'">
                                <date-picker
                                    :search-options="search"
                                    @updatePeriod="updatePeriod"
                                />
                            </template>
                            <v-divider class="my-5" />
                        </v-col>
                    </v-row>
                    <slot name="button_mail_list" />
                </section>
            </v-card>
        </v-slide-x-reverse-transition>
    </div>
</template>

<script>
import { cloneDeep, debounce, isEqual } from 'lodash'

import ThePresetWindow from '@/components/shared/TableFilters/ThePresetWindow';
import DatePicker from '@/components/shared/DatePicker'

export default {
    components: {
        DatePicker,
        ThePresetWindow
    },
    props: {
        searchFields: {
            type: Object,
            required: true
        },
        searchValues: {
            type: Object,
            required: true
        },
        activePreset: {
            type: String,
            required: false,
            default: ''
        },
        presets: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            showFilters: false,
            search: {},
            default_search: {},
            clonedSearch: {},
            isLoad: true,
            counter: 0,
            isPresetWindowOpen: false
        }
    },
    computed: {
        objectKeys() {
            return Object.keys(this.searchFields)
        },
        paramsNotAffectCounter() {
            return [
                'search_by'
            ]
        },
        searchCounter() {
            return Object.entries(this.default_search).reduce((acc, [key, val]) => {
                if (this.search[key] && this.search[key] !== val && !this.paramsNotAffectCounter.includes(key)) {
                    acc += 1
                }
                return acc
            }, 0)
        }
    },
    watch: {
        search: {
            handler: debounce(function (val) {
                const changed = this.getObjectDiff(this.clonedSearch, val)
                if (!val.search_for && (changed.includes('search_by') && changed.length === 1 && this.counter !== 0)) {
                    return
                }
                this.counter += 1
                this.clonedSearch = cloneDeep({ ...this.search })
                this.$emit('filterTable', this.search)
            }, 300),
            deep: true
        }
    },
    created() {
        this.createSearchObject()
        this.default_search = cloneDeep(this.search)
        if (this.searchValues.preset) {
            this.search.preset = this.searchValues.preset
        } else {
            this.setDataToSearch()
        }
    },
    methods: {
        include() {
            if (this.showFilters) {
                return [...document.querySelectorAll('.v-menu__content')]
            }
            return []
        },
        closeFilter() {
            this.showFilters = false
        },
        createSearchObject() {
            Object.keys(this.searchFields).forEach((item) => {
                if (item === 'datepicker') {
                    this.search = {
                        ...this.search,
                        from: '',
                        to: ''
                    }
                    return
                }
                if (item === 'search_by') {
                    this.search = {
                        ...this.search,
                        [item]: '',
                        search_for: '',
                        search_by: this.searchValues.search_by
                    }
                    return
                }
                if (this.$route.name === 'hr-clients') {
                    this.search = {
                        ...this.search,
                        [item]: '',
                        orders_count_from: '',
                        orders_count_to: '',
                        last_order_from: '',
                        last_order_to: '',
                        phone_exists: false
                    }
                } else {
                    this.search = {
                        ...this.search,
                        [item]: ''
                    }
                }
            })
        },
        setDataToSearch() {
            const searchKeys = Object.keys(this.search)
            this.search = Object.entries(this.searchValues).reduce((acc, [key, val]) => {
                if (searchKeys.includes(key) && val) {
                    acc[key] = val
                }
                return acc
            }, {})
        },
        updatePeriod(payload) {
            this.search = {
                ...this.search,
                from: payload.from,
                to: payload.to
            }
        },
        reset() {
            this.search = {
                ...this.default_search
            }
        },
        updateShortcats(value) {
            this.reset()
            this.search.preset = value
            this.isPresetWindowOpen = false
        },
        getObjectDiff(obj1, obj2) {
            const diff = Object.keys(obj1).reduce((result, key) => {
                // eslint-disable-next-line no-prototype-builtins
                if (!obj2.hasOwnProperty(key)) {
                    result.push(key);
                } else if (isEqual(obj1[key], obj2[key])) {
                    const resultKeyIndex = result.indexOf(key);
                    result.splice(resultKeyIndex, 1);
                }
                return result;
            }, Object.keys(obj2));
            return diff;
        },
        togglePresetsWindow() {
            this.isPresetWindowOpen = !this.isPresetWindowOpen
        },
        isShowFilter() {
            if (this.search.preset) {
                this.search = {
                    ...this.search,
                    preset: ''
                }
            }
            this.showFilters = !this.showFilters
        }
    }
}
</script>

<style lang="scss">
    .main_filter{
        min-height: 68px;
    }
    .sidebar-filter{
        min-width: 450px;
        width: 33%;
        position: fixed;
        z-index: 9;
        top: 0;
        bottom: 0;
        right: 0;
        &_counter{
            padding: 5px 10px;
            font-weight: bold;
            background: rgba(255, 105, 12, 0.1);
            color: #82b1ff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @media (max-width: 448px) {
            min-width: 380px;
        }
    }
    .search_field{
        max-width: 150px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .search_input{
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .filter_status_by{
        max-width: 120px;
    }
    .filter_btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }
    .filter_left{
        width: calc(100% - 110px);
        overflow-x: hidden;
        @media all and (max-width: 1099px){
            flex-wrap: wrap;
        }
        .filter_status{
            @media all and (max-width: 1099px){
                width: 100%;
            }
            &_btn {
                white-space: nowrap;
            }
        }
        &::-webkit-scrollbar {
            width: 5px;
            height: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: rgba(25, 118, 210, 0.1);
        }

    }
    .counter_wrapper{
        position: relative;
    }
    .order_counter_filter{
        position: absolute;
        display: block;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: #284067;
        top: -7px;
        right: -4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
    }
    .filter_status{
        display: flex;
        flex-wrap: wrap;
        &_btn{
            display: flex;
            align-items: center;
            margin-right: 25px;
            padding: 2px 10px;
            font-weight: 600;
            border-radius: 4px;
            border: 2px solid transparent;
            &:hover{
                background-color: rgba(25, 118, 210, 0.1);
            }
            @media all and (max-width: 1099px){
                display: inline-block;
                margin-right: 10px;
            }
            &:last-child{
                margin-right: 0;
            }
            &--active{
                //border: 2px solid #82b1ff;
                //color: #82b1ff;
                background-color: #1976d2;
                color: #fff;
                &:hover{
                    background-color: #1976d2;
                }
            }
        }
    }
    .btn_counter{
        padding: 5px;
        border-radius: 4px;
        margin-left: 10px;
        font-weight: bold;
        text-align: center;
        &-preset--active{
            color: #fff !important;
        }
        &--all {
            background: #f2f2f2;
            color: #000;
        }
        &--writer_free{
            background: rgba(89, 199, 77, 0.1);
            color: #59C74D;
        }

        &--writer_pending {
            background: rgba(255, 105, 12, 0.1);
            color: #82b1ff;
        }
        &--writer_revision {
            background: rgba(17,97,224,.1);
            color: #1161e0;
        }
        &--overdue {
            background: rgba(212, 28, 28, 0.1);
            color: #D41C1C;
        }
        &--yesterday{
            background: rgba(12, 45, 72, 0.1);
            color: rgba(12, 45, 72, 1);
        }
        &--today{
            background: rgba(46, 139, 192, 0.1);
            color: rgba(46, 139, 192, 1);
        }
        &--writer_to_refund {
            background: rgba(212, 28, 28, 0.1);
            color: #D41C1C;
        }
    }

    .filter-preset-wrapper{
        position: relative;
        padding: 8px 0;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 60px;
            left: -60px;
            z-index: 1;
            background: linear-gradient(270deg , rgb(255,255,255), rgb(255,255,255, 0.1));
        }
    }
</style>
