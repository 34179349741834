export const CREDITS_CLIENT_LIST_SEARCH = {
    datepicker: {
        cols: 12,
        value: 'datepicker'
    },
    search_by: {
        value: 'search_by',
        label: 'Search by field',
        list: [
            {
                text: 'Client ID',
                value: 'clientid'
            }
        ]
    }
}
