/* eslint-disable class-methods-use-this */
import Api from '@/helpers/api/index.js'

// import { PAYROLL_LIST_SEARCH } from '@/constants/Payroll/filter'
import { CREDITS_WRITER_LIST_SEARCH } from '@/constants/Credits/creditsWritersFilters'
import { CREDITS_CLIENT_LIST_SEARCH } from '@/constants/Credits/creditsClientsFilters'

class SearchFieldsHelper {
    async getClientInvitationField() {
        try {
            const { data } = await Api.get('/api/client-invitation/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getMessageLogSearchField() {
        try {
            const { data } = await Api.get('/api/messaging-log/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getLoginSessionSearchField() {
        try {
            const { data } = await Api.get('/api/login-session/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getOrderSearchField() {
        try {
            const { data } = await Api.get('/api/order/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getPromoSearchField() {
        try {
            const { data } = await Api.get('/api/discount/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getBatchSearchField() {
        try {
            const { data } = await Api.get('/api/order-batch/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getClientSearchField() {
        try {
            const { data } = await Api.get('/api/client/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getPayrollSearchField() {
        try {
            const { data } = await Api.get('/api/writer-payout/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getClientSupportSearchField() {
        try {
            const { data } = await Api.get('/api/support/client/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getWriterSupportSearchField() {
        try {
            const { data } = await Api.get('/api/support/writer/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getFeedbackSearchField() {
        try {
            const { data } = await Api.get('/api/testimonial/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getWriterSearchField() {
        try {
            const { data } = await Api.get('/api/writer/get-filters-object')
            return data
        } catch (error) {
            return []
        }
    }

    async getSearchField(type) {
        type = type.toLowerCase();
        if (type === 'order') {
            const list = await this.getOrderSearchField()
            delete list.sort_by
            return list
        }
        if (type === 'batch') {
            const list = await this.getBatchSearchField()
            delete list.sort_fields
            delete list.sort_by
            return list
        }
        if (type === 'clients') {
            const list = await this.getClientSearchField()
            delete list.sort_by
            return list
        }
        if (type === 'payroll') {
            const list = await this.getPayrollSearchField()
            // const search_by = list.length ? {
            //     search_by: {
            //         value: 'search_by',
            //         cols: 12,
            //         list
            //     }
            // } : null
            // return {
            //     ...PAYROLL_LIST_SEARCH,
            //     ...search_by
            // }
            delete list.sort_by
            return list
        }
        if (type === 'client-support') {
            const list = await this.getClientSupportSearchField()
            delete list.sort_fields
            delete list.sort_by
            return list
        }
        if (type === 'writer-support') {
            const list = await this.getWriterSupportSearchField()
            delete list.sort_by
            delete list.sort_fields
            return list
        }
        if (type === 'feedback') {
            const list = await this.getFeedbackSearchField()
            delete list.sort_by
            delete list.sort_fields
            return list
        }
        if (type === 'writer') {
            const list = await this.getWriterSearchField()
            delete list.sort_by
            return list
        }
        if (type === 'promo') {
            const list = await this.getPromoSearchField()
            delete list.sort_by
            return list
        }
        if (type === 'login-session') {
            const list = await this.getLoginSessionSearchField()
            delete list.sort_by
            return list
        }
        if (type === 'message-log') {
            const list = await this.getMessageLogSearchField()
            delete list.sort_by
            return list
        }
        if (type === 'client-invitation') {
            const list = await this.getClientInvitationField()
            delete list.sort_by
            return list
        }
        if (type === 'credits-writer') {
            return {
                ...CREDITS_WRITER_LIST_SEARCH
            }
        }
        if (type === 'credits-client') {
            return {
                ...CREDITS_CLIENT_LIST_SEARCH
            }
        }
        return []
    }
}

export const searchFieldsHelper = new SearchFieldsHelper()
