export const PER_PAGE = [
    {
        text: '5',
        value: 5
    },
    {
        text: '10',
        value: 10
    },
    {
        text: '20',
        value: 20
    },
    {
        text: '50',
        value: 50
    },
    {
        text: '100',
        value: 100
    }
]
