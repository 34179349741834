<template>
    <transition name="open">
        <div
            v-click-outside="closePresetsList"
            class="preset-window__wrapper"
        >
            <ul class="preset-window__presets">
                <li
                    v-for="(preset, index) in presets"
                    :key="index"
                    class="preset-window__presets-item"
                    :class="{'filter_status_btn--active' : activePreset === preset.preset}"
                    @click="setPreset(preset.value, preset.title)"
                >
                    <span class="preset-window__presets-item-title">{{ statusText(preset.title) }}</span>
                    <span
                        class="preset-window__presets-item-count"
                        :class="{'btn_counter-preset--active' : activePreset === preset.preset}"
                        :style="`background-color: ${statusBgColor(preset.preset)}; color: ${statusTextColor(preset.preset)}`"
                    >
                        {{ preset.count }}
                    </span>
                    <v-icon
                        v-if="activePreset === preset.preset"
                        class="preset-window__presets-close-icon"
                        style="color: white"
                        @click.stop="setPreset('')"
                    >
                        mdi-close
                    </v-icon>
                </li>
            </ul>
        </div>
    </transition>
</template>

<script>
import { statusBgColor, statusText, statusTextColor } from '@/helpers/order/statusHelper'

export default {
    name: 'OrderPresetWindow',
    props: {
        presets: {
            type: Array,
            default: () => ([])
        },
        activePreset: {
            type: String,
            required: false,
            default: ''
        }
    },
    methods: {
        statusText,
        statusBgColor,
        statusTextColor,
        setPreset(value, title) {
            this.$emit('setPreset', value, title)
        },
        closePresetsList() {
            this.$emit('closePresetsList')
        }
    }
}
</script>

<style lang="scss">
.preset-window{
    &__wrapper{
        background-color: #fff;
        position: absolute ;
        z-index: 20;
        top: 65px;
        right: -30px;
        width: 300px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 3px rgb(25 50 71 / 25%);
    }
    &__presets{
        padding-left: 0 !important;
        border-radius: 10px;
    }
    &__presets-item{
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
        .active{
            color: #fff;
            background-color: #1161E0;
        }
        &:hover{
            background-color: rgba(25, 118, 210, 0.1);
        }
    }
    &__presets-item-count{
        padding: 5px;
        border-radius: 5px;
        font-weight: 500;
        background: rgba(17, 97, 224, 0.1);
        color: #000000;
    }
    &__presets-item-title{
        width: 60%;
    }
    &__presets-close-icon{
        width: 10%;
    }
}

.open-enter,
.open-leave-to {
    opacity: 0;
    transform: perspective(800px) rotateX(-15deg);
    transition: opacity .3s linear, transform 0.3s linear;
}

.open-enter-to,
.open-leave {
    opacity: 1;
    transform: perspective(800px) rotateX(0);
    transition: opacity .3s linear, transform 0.3s linear;
}
</style>
