<template>
    <div
        class="d-flex"
    >
        <span
            v-for="preset in presets"
            :key="preset.preset"
            transparent
            class="filter_status_btn"
            :class="{'filter_status_btn--active' : activePreset === preset.preset}"
            style="cursor: pointer;"
            @click="updateShortcats(preset.preset)"
        >
            <span>
                {{ statusText(preset.title) }}
            </span>
            <span
                class="btn_counter"
                :class="{'btn_counter-preset--active' : activePreset === preset.preset}"
                :style="`background-color: ${statusBgColor(preset.preset)}; color: ${statusTextColor(preset.preset)}`"
            >
                {{ preset.count }}
            </span>
            <v-icon
                v-if="activePreset === preset.preset"
                class="ml-2"
                style="color: white"
                @click.stop="updateShortcats('')"
            >
                mdi-close
            </v-icon>
        </span>
    </div>
</template>

<script>
import { statusBgColor, statusText, statusTextColor } from '@/helpers/order/statusHelper'

export default {
    props: {
        activePreset: {
            type: String,
            required: false,
            default: ''
        },
        presets: {
            type: Array,
            required: true
        }
    },
    methods: {
        statusText,
        statusBgColor,
        statusTextColor,
        updateShortcats(val) {
            this.$emit('update:shortcat', val)
        }
    }
}
</script>

<style>

</style>
