export const PERIODS_LIST = [
    {
        text: 'All',
        value: 'all'
    },
    {
        text: 'Today',
        value: 'today'
    },
    {
        text: 'Yesterday',
        value: 'yesterday'
    },
    {
        text: 'Last 7 days',
        value: 'seven_days'
    },
    {
        text: 'Last 30 days',
        value: 'thirty_days'
    },
    {
        text: 'This month',
        value: 'this_month'
    },
    {
        text: 'Last month',
        value: 'last_month'
    }
]
