<template>
    <tbody>
        <tr
            v-for="(item, index) in perPage"
            :key="index"
        >
            <td
                :colspan="columnCount"
                class="skeleton-td"
            >
                <v-skeleton-loader
                    type="list-item, divider"
                />
            </td>
        </tr>
    </tbody>
</template>
<script>
export default {
    name: 'TableSkeleton',
    props: {
        perPage: {
            type: Number,
            required: true
        },
        columnCount: {
            type: Number,
            required: true
        }
    }
}
</script>
<style lang="scss">
.skeleton{
    &-td{
        padding: 0 !important;
        border: none !important;
    }
}
</style>
