<template>
    <v-row
        v-if="listLenght"
        class="d-flex align-center justify-center pt-10 pb-10"
    >
        <v-col
            class="flex-grow-0 offset-1 pt-0 pb-0"
        >
            <v-pagination
                v-model="search.page"
                :length="lastPage"
                :total-visible="7"
                @input="paginationEvent"
            />
        </v-col>
        <v-col
            class="pt-0 pb-0 flex-grow-0"
        >
            <v-select
                v-model="search.per_page"
                :items="perPages"
                hide-details
                outlined
                label="Rows per page"
                @change="updatePerpage"
            />
        </v-col>
    </v-row>
</template>
<script>
import {
    PER_PAGE
} from '@/constants/PerPages'

export default {
    props: {
        listLenght: {
            type: Number,
            required: true
        },
        lastPage: {
            type: Number,
            required: true
        },
        totalVisible: {
            type: Number,
            required: true
        },
        searchOptions: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            search: {
                per_page: 10,
                page: 1
            }
        }
    },
    computed: {
        perPages() {
            return PER_PAGE
        }
    },
    watch: {
        searchOptions() {
            this.search = { ...this.search, ...this.searchOptions }
        }
    },
    created() {
        this.search = { ...this.search, ...this.searchOptions }
    },
    methods: {
        paginationEvent(e) {
            this.$emit('updatePage', e)
        },
        updatePerpage() {
            this.search.page = 1
            this.$emit('updatePerpage', this.search)
        }
    }
}
</script>

<style lang="scss">

.v-pagination .v-pagination__navigation {
    @media (max-width: 599px) {
        width: auto;
        min-width: 22px !important;
        height: 22px !important;
    }
}

.v-pagination .v-pagination__item {
    @media (max-width: 599px) {
        width: auto;
        min-width: 22px !important;
        height: 22px !important;
        font-size: 14px;
    }
}

</style>
